<template>
  <tbody v-if="aggregates != null" class="bg-white divide-y divide-gray-200">
    <tr v-for="(item, index) in aggregates" :key="index">
      <td
        v-if="item.pk.index === index"
        :rowspan="item.pk.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ item.pk.value }}
      </td>
      <td
        v-if="item.sk.index === index"
        :rowspan="item.sk.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ item.sk.value }}
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        {{ item.serviceDetail }}
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <p>{{ d(item.contractStartAt, 'ymd') }}{{ phyphone }}</p>
        <p>{{ d(item.contractEndAt, 'ymd') }}</p>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        {{ n(item.totalPriceForContract.exTax, 'currency') }}<br />
        {{ '(' + n(item.totalPriceForContract.incTax, 'currency') + ')' }}
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <template v-if="item.depositDate != null">
          {{ d(item.depositDate, 'ymdJst') }}
        </template>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <p>{{ d(item.startAt, 'ymdJst') }}{{ phyphone }}</p>
        <p>{{ d(item.endAt, 'ymdJst') }}</p>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        {{ n(item.price.exTax, 'currency') }}<br />
        {{ '(' + n(item.price.incTax, 'currency') + ')' }}
      </td>
      <td
        v-if="item.subtotal.index === index"
        :rowspan="item.subtotal.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ n(item.subtotal.value.exTax, 'currency') }}<br />
        {{ '(' + n(item.subtotal.value.incTax, 'currency') + ')' }}
      </td>
      <td
        v-if="item.total.index === index"
        :rowspan="item.total.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ n(item.total.value.exTax, 'currency') }}<br />
        {{ '(' + n(item.total.value.incTax, 'currency') + ')' }}
      </td>

      <td class="px-6 py-4 whitespace-nowrap">
        {{ n(item.able.exTax, 'currency') }}<br />
        {{ '(' + n(item.able.incTax, 'currency') + ')' }}
      </td>
      <td
        v-if="item.ableSubtotal.index === index"
        :rowspan="item.ableSubtotal.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ n(item.ableSubtotal.value.exTax, 'currency') }}<br />
        {{ '(' + n(item.ableSubtotal.value.incTax, 'currency') + ')' }}
      </td>
      <td
        v-if="item.ableTotal.index === index"
        :rowspan="item.ableTotal.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ n(item.ableTotal.value.exTax, 'currency') }}<br />
        {{ '(' + n(item.ableTotal.value.incTax, 'currency') + ')' }}
      </td>

      <td class="px-6 py-4 whitespace-nowrap">
        {{ n(item.ed.exTax, 'currency') }}<br />
        {{ '(' + n(item.ed.incTax, 'currency') + ')' }}
      </td>
      <td
        v-if="item.edSubtotal.index === index"
        :rowspan="item.edSubtotal.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ n(item.edSubtotal.value.exTax, 'currency') }}<br />
        {{ '(' + n(item.edSubtotal.value.incTax, 'currency') + ')' }}
      </td>
      <td
        v-if="item.edTotal.index === index"
        :rowspan="item.edTotal.rowSpan"
        class="px-6 py-4 whitespace-nowrap"
      >
        {{ n(item.edTotal.value.exTax, 'currency') }}<br />
        {{ '(' + n(item.edTotal.value.incTax, 'currency') + ')' }}
      </td>
    </tr>
  </tbody>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CAggregate } from '@/types/component'
import { useTranslation } from '@/locales'

export default defineComponent({
  name: 'AggregateList',
  props: {
    aggregates: {
      type: Object as PropType<CAggregate[] | undefined>,
      required: true,
    },
  },

  setup(props) {
    const { t, d, n } = useTranslation()
    const phyphone = ' - '

    console.log('hoehogeogoeh', props.aggregates)

    return { t, d, n, phyphone }
  },
})
</script>
