
import { defineComponent, PropType } from 'vue'
import { CAggregate } from '@/types/component'
import { useTranslation } from '@/locales'

export default defineComponent({
  name: 'AggregateList',
  props: {
    aggregates: {
      type: Object as PropType<CAggregate[] | undefined>,
      required: true,
    },
  },

  setup(props) {
    const { t, d, n } = useTranslation()
    const phyphone = ' - '

    console.log('hoehogeogoeh', props.aggregates)

    return { t, d, n, phyphone }
  },
})
