import {
  RowString,
  RowPrice,
  Price,
  TempAggrInfo,
  CAggregate,
} from '@/types/component'
import { ApiAggrResult } from '@/types/api'
import clonedeep from 'lodash.clonedeep'

export function rowString(
  index: number,
  rowSpan: number,
  value: string
): RowString {
  return {
    index,
    rowSpan,
    value,
  } as RowString
}

export function rowPrice(
  index: number,
  rowSpan: number,
  value: Price
): RowPrice {
  return {
    index,
    rowSpan,
    value: {
      exTax: value.exTax,
      incTax: value.incTax,
    },
  } as RowPrice
}

export function getAggregate(result: ApiAggrResult, temp: TempAggrInfo) {
  return {
    pk: rowString(temp.pk.index, temp.pk.rowSpan, temp.pk.value),
    sk: rowString(temp.sk.index, temp.sk.rowSpan, temp.sk.value),
    serviceDetail: result.serviceDetail,
    contractStartAt: result.contractStartAt,
    contractEndAt: result.contractEndAt,
    totalPriceForContract: { exTax: result.total, incTax: result.totalWithTax },
    startAt: result.startAt,
    endAt: result.endAt,
    price: { exTax: result.price, incTax: result.priceWithTax },
    subtotal: rowPrice(
      temp.subtotal.index,
      temp.subtotal.rowSpan,
      temp.subtotal.value
    ),
    total: rowPrice(temp.total.index, temp.total.rowSpan, temp.total.value),
    able: { exTax: result.able, incTax: result.ableWithTax },
    ableSubtotal: rowPrice(
      temp.ableSubtotal.index,
      temp.ableSubtotal.rowSpan,
      temp.ableSubtotal.value
    ),
    ableTotal: rowPrice(
      temp.ableTotal.index,
      temp.ableTotal.rowSpan,
      temp.ableTotal.value
    ),
    ed: { exTax: result.ed, incTax: result.edWithTax },
    edSubtotal: rowPrice(
      temp.edSubtotal.index,
      temp.edSubtotal.rowSpan,
      temp.edSubtotal.value
    ),
    edTotal: rowPrice(
      temp.edTotal.index,
      temp.edTotal.rowSpan,
      temp.edTotal.value
    ),
    depositDate: result.depositDate,
  }
}

export function getInitTempAggrInfo() {
  const temp: TempAggrInfo = {
    pk: { index: 0, rowSpan: 1, value: '' },
    sk: { index: 0, rowSpan: 1, value: '' },
    subtotal: { index: 0, rowSpan: 1, value: { exTax: 0, incTax: 0 } },
    total: { index: 0, rowSpan: 1, value: { exTax: 0, incTax: 0 } },
    ableSubtotal: { index: 0, rowSpan: 1, value: { exTax: 0, incTax: 0 } },
    ableTotal: { index: 0, rowSpan: 1, value: { exTax: 0, incTax: 0 } },
    edSubtotal: { index: 0, rowSpan: 1, value: { exTax: 0, incTax: 0 } },
    edTotal: { index: 0, rowSpan: 1, value: { exTax: 0, incTax: 0 } },
  }

  return temp
}

function _addNewAggr(
  index: number,
  result: ApiAggrResult,
  temp: TempAggrInfo,
  cAggregate: CAggregate[]
) {
  temp.pk.index = index
  temp.pk.rowSpan = 1
  temp.pk.value = result.pk
  temp.sk.index = index
  temp.sk.rowSpan = 1
  temp.sk.value = result.sk
  temp.subtotal.index = index
  temp.subtotal.rowSpan = 1
  temp.subtotal.value.exTax = result.price
  temp.subtotal.value.incTax = result.priceWithTax
  temp.total.index = index
  temp.total.rowSpan = 1
  temp.total.value.exTax = result.price
  temp.total.value.incTax = result.priceWithTax
  temp.ableSubtotal.index = index
  temp.ableSubtotal.rowSpan = 1
  temp.ableSubtotal.value.exTax = result.able
  temp.ableSubtotal.value.incTax = result.ableWithTax
  temp.ableTotal.index = index
  temp.ableTotal.rowSpan = 1
  temp.ableTotal.value.exTax = result.able
  temp.ableTotal.value.incTax = result.ableWithTax
  temp.edSubtotal.index = index
  temp.edSubtotal.rowSpan = 1
  temp.edSubtotal.value.exTax = result.ed
  temp.edSubtotal.value.incTax = result.edWithTax
  temp.edTotal.index = index
  temp.edTotal.rowSpan = 1
  temp.edTotal.value.exTax = result.ed
  temp.edTotal.value.incTax = result.edWithTax

  cAggregate.push(getAggregate(result, temp))

  return cAggregate
}

function _addDifferentAggr(
  index: number,
  result: ApiAggrResult,
  temp: TempAggrInfo,
  cAggregateCustomers: CAggregate[]
) {
  temp.pk.rowSpan++
  temp.sk.index = index
  temp.sk.rowSpan = 1
  temp.sk.value = result.sk

  temp.subtotal.index = index
  temp.subtotal.rowSpan = 1
  temp.subtotal.value.exTax = result.price
  temp.subtotal.value.incTax = result.priceWithTax
  temp.total.rowSpan++
  temp.total.value.exTax += result.price
  temp.total.value.incTax += result.priceWithTax

  temp.ableSubtotal.index = index
  temp.ableSubtotal.rowSpan = 1
  temp.ableSubtotal.value.exTax = result.able
  temp.ableSubtotal.value.incTax = result.ableWithTax
  temp.ableTotal.rowSpan++
  temp.ableTotal.value.exTax += result.able
  temp.ableTotal.value.incTax += result.ableWithTax

  temp.edSubtotal.index = index
  temp.edSubtotal.rowSpan = 1
  temp.edSubtotal.value.exTax = result.ed
  temp.edSubtotal.value.incTax = result.edWithTax
  temp.edTotal.rowSpan++
  temp.edTotal.value.exTax += result.ed
  temp.edTotal.value.incTax += result.edWithTax

  // Update previous customer data
  cAggregateCustomers = cAggregateCustomers.map((res) => {
    if (res.pk.value === temp.pk.value) {
      res.pk.rowSpan = temp.pk.rowSpan
      res.total.rowSpan = temp.total.rowSpan
      res.total.value = temp.total.value

      res.ableTotal.rowSpan = temp.ableTotal.rowSpan
      res.ableTotal.value = temp.ableTotal.value

      res.edTotal.rowSpan = temp.edTotal.rowSpan
      res.edTotal.value = temp.edTotal.value
    }
    return res
  })

  // Add a new service
  cAggregateCustomers.push(getAggregate(result, temp))

  return cAggregateCustomers
}

function _addSameAggr(
  index: number,
  result: ApiAggrResult,
  temp: TempAggrInfo,
  cAggregate: CAggregate[]
) {
  temp.pk.rowSpan++
  temp.sk.rowSpan++

  temp.subtotal.rowSpan++
  temp.subtotal.value.exTax += result.price
  temp.subtotal.value.incTax += result.priceWithTax
  temp.total.rowSpan++
  temp.total.value.exTax += result.price
  temp.total.value.incTax += result.priceWithTax

  temp.ableSubtotal.rowSpan++
  temp.ableSubtotal.value.exTax += result.able
  temp.ableSubtotal.value.incTax += result.ableWithTax

  temp.ableTotal.rowSpan++
  temp.ableTotal.value.exTax += result.able
  temp.ableTotal.value.incTax += result.ableWithTax

  temp.edSubtotal.rowSpan++
  temp.edSubtotal.value.exTax += result.ed
  temp.edSubtotal.value.incTax += result.edWithTax

  temp.edTotal.rowSpan++
  temp.edTotal.value.exTax += result.ed
  temp.edTotal.value.incTax += result.edWithTax

  // Update previous customer data
  cAggregate = cAggregate.map((res) => {
    if (res.pk.value === temp.pk.value) {
      res.total.rowSpan = temp.total.rowSpan
      res.total.value = temp.total.value

      res.ableTotal.rowSpan = temp.ableTotal.rowSpan
      res.ableTotal.value = temp.ableTotal.value

      res.edTotal.rowSpan = temp.edTotal.rowSpan
      res.edTotal.value = temp.edTotal.value

      res.pk.rowSpan = temp.pk.rowSpan
      if (res.sk.value === temp.sk.value) {
        res.sk.rowSpan = temp.sk.rowSpan
        res.subtotal.rowSpan = temp.subtotal.rowSpan
        res.subtotal.value = temp.subtotal.value

        res.ableSubtotal.rowSpan = temp.ableSubtotal.rowSpan
        res.ableSubtotal.value = temp.ableSubtotal.value

        res.edSubtotal.rowSpan = temp.edSubtotal.rowSpan
        res.edSubtotal.value = temp.edSubtotal.value
      }
    }
    return res
  })

  // Add a new service
  cAggregate.push(getAggregate(result, temp))

  return cAggregate
}

export function swap(a: ApiAggrResult) {
  const temp = a.pk
  a.pk = a.sk
  a.sk = temp
  return a
}

export function convertAggrResultToUiFormat(
  apiResult: ApiAggrResult[],
  swap?: (res: ApiAggrResult) => ApiAggrResult
): CAggregate[] {
  let aggr: CAggregate[] = []

  const temp = getInitTempAggrInfo()
  const result = swap == null ? apiResult : clonedeep(apiResult)

  result
    .map((a) => {
      return swap == null ? a : swap(a)
    })
    .sort((a, b) => {
      if (a.pk !== b.pk) {
        if (a.pk < b.pk) return -1
        if (b.pk < a.pk) return 1
      }

      if (a.sk !== b.sk) {
        if (a.sk < b.sk) return -1
        if (b.sk < a.sk) return 1
      }

      return 0
    })
    .forEach((result, index) => {
      if (temp.pk.value !== result.pk) {
        aggr = _addNewAggr(index, result, temp, aggr)
      } else if (temp.sk.value !== result.sk) {
        aggr = _addDifferentAggr(index, result, temp, aggr)
      } else {
        aggr = _addSameAggr(index, result, temp, aggr)
      }
    })
  return aggr
}
